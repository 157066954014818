import { fromJS, Map } from "immutable"
import Router from "next/router"
import * as CategoriesApi from "highline/api/admin/categories_api"
import AdminActionTypes from "highline/redux/admin/action_types"

export const categoriesFetchAllFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_FETCH_ALL_FAILED,
  error,
})

export const categoriesFetchAllSucceeded = (categories) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_FETCH_ALL_SUCCEEDED,
  categories,
})

export const categoryCreateFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_CREATE_FAILED,
  error,
})

export const categoryCreateSucceeded = (data) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_CREATE_SUCCEEDED,
  data,
})

export const categoryDeleteFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_DELETE_FAILED,
  error,
})

export const categoryDeleteSucceeded = (data) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_DELETE_SUCCEEDED,
  data,
})

export const categoryRequestCompleted = () => ({
  type: AdminActionTypes.ADMIN_CATEGORY_REQUEST_COMPLETED,
})

export const categoryRequestStarted = () => ({
  type: AdminActionTypes.ADMIN_CATEGORY_REQUEST_STARTED,
})

export const categoryFetchSucceeded = (category) => ({
  type: AdminActionTypes.ADMIN_CATEGORY_FETCH_SUCCEEDED,
  category,
})

export const categoryFetchFailed = () => ({
  type: AdminActionTypes.ADMIN_CATEGORY_FETCH_FAILED,
})

export const categoryUpdateSucceeded = (category) => ({
  type: AdminActionTypes.ADMIN_CATEGORY_UPDATE_SUCCEEDED,
  category,
})

export const categoryUpdateFailed = (errors) => ({
  type: AdminActionTypes.ADMIN_CATEGORY_UPDATE_FAILED,
  errors,
})

export const categoryVisibilityToggled = (node, expanded) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_VISIBILITY_TOGGLED,
  expanded,
  node,
})

export const onChangeTreeData = (list) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_TREE_DATA_CHANGED,
  list,
})

export const addChildClicked = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_ADD_CHILD_CLICKED,
})

export const modalCloseClicked = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MODAL_CLOSE_CLICKED,
})

export const createCategoryInputChanged = (name, value) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_CREATE_CATEGORY_INPUT_CHANGED,
  name,
  value,
})

export const manageProgramsColorManagementCloseClicked = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_CLOSE_CLICKED,
})

export const manageProgramsPageLoaded = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOADED,
})

export const manageProgramsSearchInputChanged = (searchText) => (
  (dispatch, getState) => {
    const inputChangedAt = new Date().getTime()
    dispatch({
      type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_SEARCH_INPUT_CHANGED,
      searchText,
      timeNow: inputChangedAt,
    })

    if (searchText.length < 4) { return }

    // don't make api request unless input hasn't changed in the past 1 second
    setTimeout(() => {
      const searchInputLastChangedAt = getState().getIn(["categories", "managePrograms", "searchInputLastChangedAt"])
      const timeNow = new Date().getTime()
      const secondsSinceLastInputChange = (timeNow - searchInputLastChangedAt) / 1000
      if (secondsSinceLastInputChange < 1) { return }

      return dispatch(manageProgramsFetchSearchResultsAsync(searchText))
    }, 1000)
  }
)

export const manageProgramsManageProgramClicked = (categoryProgramId) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_MANAGE_PROGRAM_CLICKED,
  categoryProgramId,
})

export const manageProgramsAddProgramFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_ADD_PROGRAM_FAILED,
  error,
})

export const manageProgramsAddProgramSucceeded = (categoryPrograms) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_ADD_PROGRAM_SUCCEEDED,
  categoryPrograms,
})

export const manageProgramsDeleteProgramFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_PROGRAM_FAILED,
  error,
})

export const manageProgramsDeleteProgramCompleted = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_PROGRAM_COMPLETED,
})

export const manageProgramsDeleteProgramSucceeded = (categoryPrograms) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_PROGRAM_SUCCEEDED,
  categoryPrograms,
})

export const manageProgramsUpdateProgramFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_UPDATE_PROGRAM_FAILED,
  error,
})

export const manageProgramsUpdateProgramSucceeded = (categoryPrograms) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_UPDATE_PROGRAM_SUCCEEDED,
  categoryPrograms,
})

export const manageProgramsLoadSucceeded = (category) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOAD_SUCCEEDED,
  category,
})

export const manageProgramsLoadFailed = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOAD_FAILED,
})

export const editCategoryInputChanged = (name, value) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_EDIT_CATEGORY_INPUT_CHANGED,
  name,
  value,
})

export const manageProgramsFilterTypeChanged = (filterType) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FILTER_TYPE_CHANGED,
  filterType,
})

export const manageProgramsFiltersUpdateSucceeded = (filterValues) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FILTERS_UPDATE_SUCCEEDED,
  filterValues,
})

export const manageProgramsColorManagementSearchInputChanged = (searchText) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_SEARCH_INPUT_CHANGED,
  searchText,
})

export const manageProgramsColorManagementSearchSelected = (_, searchValue) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_SEARCH_SELECTED,
  searchValue,
})

export const manageProgramsCreateCategoryProgramRuleTypeSucceeded = (data) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_CREATE_CATEGORY_PROGRAM_RULE_TYPE_SUCCEEDED,
  data,
})

export const manageProgramsCreateCategoryProgramRuleTypeFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_CREATE_CATEGORY_PROGRAM_RULE_TYPE_FAILED,
  error,
})

export const manageProgramsDeleteCategoryProgramRuleSucceeded = (data) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_CATEGORY_PROGRAM_RULE_SUCCEEDED,
  data,
})

export const manageProgramsDeleteCategoryProgramRuleFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_CATEGORY_PROGRAM_RULE_FAILED,
  error,
})

export const manageProgramsCreateCategoryProgramRuleSucceeded = (data) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_CATEGORY_PROGRAM_RULE_SUCCEEDED,
  data,
})

export const manageProgramsCreateCategoryProgramRuleFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_CATEGORY_PROGRAM_RULE_FAILED,
  error,
})

export const manageProgramsFetchCategoryProgramSucceeded = (data) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_CATEGORY_PROGRAM_SUCCEEDED,
  data,
})

export const manageProgramsFetchCategoryProgramFailed = (error) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_CATEGORY_PROGRAM_FAILED,
  error,
})

export const manageProgramsFetchSearchResultsStarted = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_STARTED,
})

export const manageProgramsFetchSearchResultsCompleted = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_COMPLETED,
})

export const manageProgramsFetchSearchResultsSucceeded = (programs) => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_SUCCEEDED,
  programs,
})

export const manageProgramsFetchSearchResultsFailed = () => ({
  type: AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_FAILED,
})

export const fetchAllCategoriesAsync = () => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])

    try {
      const response = await CategoriesApi.fetchAll(authenticationToken)
      dispatch(categoriesFetchAllSucceeded(response.data))

    } catch (error) {
      dispatch(categoriesFetchAllFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }
    dispatch(categoryRequestCompleted())
  }
)

export const addProgramAsync = (program) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const categoryId = getState().getIn(["categories", "managePrograms", "categoryId"])
    const program_id = program.id
    const program_type = program.type

    try {
      const response = await CategoriesApi.addProgram(authenticationToken, categoryId, program_id, program_type)
      dispatch(manageProgramsAddProgramSucceeded(response.data))

    } catch (error) {
      dispatch(manageProgramsAddProgramFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }

    dispatch(categoryRequestCompleted())
  }
)

export const deleteProgramAsync = (program) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const categoryId = getState().getIn(["categories", "managePrograms", "categoryId"])
    const programId = program.get("id")

    try {
      const response = await CategoriesApi.destroyProgram(authenticationToken, categoryId, programId)
      dispatch(manageProgramsDeleteProgramSucceeded(response.data))

    } catch (error) {
      dispatch(manageProgramsDeleteProgramFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }

    dispatch(categoryRequestCompleted())
  }
)

export const updateProgramAsync = (oldIndex, newIndex) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const categoryId = getState().getIn(["categories", "managePrograms", "categoryId"])
    const program = getState().getIn(["categories", "managePrograms", "programs", oldIndex])

    try {
      const response = await CategoriesApi.updateProgram(authenticationToken, categoryId, program.get("id"), newIndex)
      dispatch(manageProgramsUpdateProgramSucceeded(response.data))

    } catch (error) {
      dispatch(manageProgramsUpdateProgramFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }

    dispatch(categoryRequestCompleted())
  }
)

export const manageProgramsLoadAsync = (categoryId) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])

    try {
      const response = await CategoriesApi.fetch(authenticationToken, categoryId)
      dispatch(manageProgramsLoadSucceeded(response.data))
    } catch (error) {
      dispatch(manageProgramsLoadFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }
    dispatch(categoryRequestCompleted())
  }
)

export const manageProgramsFetchSearchResultsAsync = (searchText) => (
  async (dispatch, getState) => {
    const authenticationToken = getState().getIn(["auth", "authenticationToken"])

    dispatch(manageProgramsFetchSearchResultsStarted())

    try {
      const response = await CategoriesApi.fetchSearchedPrograms(authenticationToken, searchText)
      dispatch(manageProgramsFetchSearchResultsSucceeded(response.data))
    } catch (error) {
      dispatch(manageProgramsFetchSearchResultsFailed(error))
    }

    dispatch(manageProgramsFetchSearchResultsCompleted())
  }
)

export const fetchCategoryAsync = (categoryId) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())
    const authenticationToken = getState().getIn(["auth", "authenticationToken"])

    try {
      const response = await CategoriesApi.fetch(authenticationToken, categoryId)
      dispatch(categoryFetchSucceeded(response.data))
    } catch (error) {
      dispatch(categoryFetchFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }
    dispatch(categoryRequestCompleted())
  }
)

export const createCategoryAsync = (parentId) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const state = getState()
    const authenticationToken = state.getIn(["auth", "authenticationToken"])
    const name = state.getIn(["categories", "index", "newCategoryName"])
    const slug = state.getIn(["categories", "index", "newCategorySlug"])

    try {
      const response = await CategoriesApi.create(authenticationToken, name, parentId, slug)
      Router.push(
        "/admin-new/categories/[id]",
        `/admin-new/categories/${response.data.get("id")}`,
      )
      dispatch(categoryCreateSucceeded(response.data))
    } catch (error) {
      dispatch(categoryCreateFailed(error.data))
    }
    dispatch(categoryRequestCompleted())
  }
)

export const deleteCategoryAsync = (categoryId) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const state = getState()
    const authenticationToken = state.getIn(["auth", "authenticationToken"])

    try {
      const response = await CategoriesApi.destroy(authenticationToken, categoryId)
      await dispatch(categoryDeleteSucceeded(response.data))
      await dispatch(fetchAllCategoriesAsync())
    } catch (error) {
      dispatch(categoryDeleteFailed(error.data))
    }
    dispatch(categoryRequestCompleted())
  }
)

export const updateCategoryAsync = () => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const state = getState()
    const authenticationToken = state.getIn(["auth", "authenticationToken"])
    const category = state.getIn(["categories", "edit", "category"])

    try {
      const response = await CategoriesApi.update(authenticationToken, category)
      dispatch(categoryUpdateSucceeded(response.data))
    } catch (error) {
      dispatch(categoryUpdateFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }
    dispatch(categoryRequestCompleted())
  }
)

export const updateCategoryFiltersAsync = (categoryId, filters, isNewOnly) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())
    const state = getState()
    const authenticationToken = state.getIn(["auth", "authenticationToken"])
    const category = filters.merge({ id: categoryId, isNewOnly })

    try {
      const response = await CategoriesApi.update(authenticationToken, category)

      const filterValues = Map({
        isFinalSale: response.data.get("isFinalSale"),
        isFullPrice: response.data.get("isFullPrice"),
        isNewOnly,
        isSale: response.data.get("isSale"),
      })
      dispatch(manageProgramsFiltersUpdateSucceeded(filterValues))
    } catch (error) {
      dispatch(categoryUpdateFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }
    dispatch(categoryRequestCompleted())
  }
)

export const updateCategoryPositionAsync = (categoryId, position) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const categoryData = Map({ id: categoryId, position })

    try {
      await CategoriesApi.update(authenticationToken, categoryData)
      dispatch(categoryRequestCompleted())

    } catch (error) {
      dispatch(categoryUpdateFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })

      // Reload valid state
      const response = await CategoriesApi.fetchAll(authenticationToken)
      dispatch(categoriesFetchAllSucceeded(response.data))
      dispatch(categoryRequestCompleted())
    }
  }
)

export const manageProgramsCreateCategoryProgramRuleTypeAsync = (isInclude) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const categoryId = getState().getIn(["categories", "managePrograms", "categoryId"])
    const categoryProgramId = getState().getIn(["categories", "managePrograms", "currentCategoryProgramId"])
    const filterType = (isInclude) ? "include" : "exclude"

    try {
      const response = await CategoriesApi.createCategoryProgramRuleType(authenticationToken, categoryId, categoryProgramId, filterType)
      dispatch(manageProgramsCreateCategoryProgramRuleTypeSucceeded(response.data))
      dispatch(manageProgramsFilterTypeChanged(filterType))
    } catch (error) {
      dispatch(manageProgramsCreateCategoryProgramRuleTypeFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }

    dispatch(categoryRequestCompleted())
  }
)

export const fetchCategoryProgramAsync = (categoryProgramId) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const categoryId = getState().getIn(["categories", "managePrograms", "categoryId"])

    try {
      const response = await CategoriesApi.fetchCategoryProgram(authenticationToken, categoryId, categoryProgramId)
      dispatch(manageProgramsFetchCategoryProgramSucceeded(response.data))
    } catch (error) {
      dispatch(manageProgramsFetchCategoryProgramFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }

    dispatch(categoryRequestCompleted())
  }
)

export const manageProgramsCreateCategoryProgramRuleAsync = (selected, filterType) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const categoryId = getState().getIn(["categories", "managePrograms", "categoryId"])
    const categoryProgramId = getState().getIn(["categories", "managePrograms", "currentCategoryProgramId"])
    const optionValueId = selected.get("optionValueId")

    try {
      const response = await CategoriesApi.createCategoryProgramRule(authenticationToken, categoryId, categoryProgramId, filterType, optionValueId)
      dispatch(manageProgramsCreateCategoryProgramRuleSucceeded(response.data))
    } catch (error) {
      dispatch(manageProgramsCreateCategoryProgramRuleFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }

    dispatch(categoryRequestCompleted())
  }
)

export const manageProgramsDeleteCategoryProgramRuleAsync = (selected, filterType) => (
  async (dispatch, getState) => {
    dispatch(categoryRequestStarted())

    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const categoryId = getState().getIn(["categories", "managePrograms", "categoryId"])
    const categoryProgramId = getState().getIn(["categories", "managePrograms", "currentCategoryProgramId"])
    const optionValueId = selected.get("optionValueId")

    try {
      const response = await CategoriesApi.destroyCategoryProgramRule(authenticationToken, categoryId, categoryProgramId, filterType, optionValueId)
      dispatch(manageProgramsDeleteCategoryProgramRuleSucceeded(response.data))
    } catch (error) {
      dispatch(manageProgramsDeleteCategoryProgramRuleFailed(error.data.get("errors", Map())))
      setTimeout(() => { throw error })
    }

    dispatch(categoryRequestCompleted())
  }
)
